<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row>
        <b-col class="text-right">
          <b-button
            to="/coupons/create"
            size="sm"
            variant="success"
            class="mb-1"
          >
            <feather-icon icon="PlusSquareIcon" />
            {{$t('Create')}}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="coupons && Object.keys(coupons.data).length > 0">
        <template v-for="(data, index) in coupons.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-card-title>{{data.title}} ( {{data.code}} )</b-card-title>
                    <b-row class="m-0">
                      #{{data.id}} -
                      {{$t('Apply On')}} {{data.type}}
                      <b-badge class="d-block ml-1 mr-1" variant="light-success">
                        {{data.amount}} {{data.calc == 'percent' ? '%' : 'SAR'}}
                      </b-badge>
                      {{$t('Start')}}: {{data.start_date}} -
                      {{$t('End')}}: {{data.end_date}}
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    :to="`/coupons/${data.id}`"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-if="!coupons.data[index].orders.length"
                    variant="outline-danger"
                    class="btn-icon"
                    v-on:click="deleteCoupon(data)"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="coupons" :limit="7" :data="coupons" @pagination-change-page="getCoupons"></pagination>
    </b-overlay>
  </div>

</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'Coupons',
  data() {
    return {
      coupons: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getCoupons();
  },
  methods: {
    getCoupons(page = 1){
      this.isLoading = true
      useJwt.get('/coupons/get-all',{params: {
          page: page,
      }})
      .then((response) => {
        //console.log(response.data.data)
        this.coupons = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    deleteCoupon(coupon) {
        if(confirm("Do you really want to delete coupon #"+coupon.id+"?")){
            this.isLoading = true
            useJwt.post('/coupons/destroy',coupon)
            .then((response) => {
              this.coupons = this.getCoupons()
              this.isLoading = false
            })
            .catch(response => {
              // console.log(response);
            });
        }
    }
  }
}
</script>
